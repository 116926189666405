import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Context/LayoutContext";
import { useToasts } from "react-toast-notifications";
require("firebase/database")

export default function NuevoGrupo() {
    let { grupoID } = useParams();
    const { addToast } = useToasts();
    const {t} = useTranslation()
    const [form, setForm] = useState({})
    const { user } = useContext(UserContext)
    const history = useHistory();
    const { setTitle } = useContext(LayoutContext)

    const guardarGrupo = (ev) => {
        ev.preventDefault();
        console.log("el evento", form)
        const ref = firebase.database().ref("grupos").child(user.user_id)
        if(grupoID){
            ref.child(grupoID).update(form).then(() => {
                addToast("The Group has been updated successfully", { appearance: 'success', autoDismiss: true });
                history.push("/panel/groups/list")
            })
        } else {
            ref.push(form).then((e) => {
                addToast("The Group has been created, now add the students", { appearance: 'success', autoDismiss: true });
                history.push(`/panel/groups/select-guests/${e.key}`)
            })
        }
        return false
    }
    useEffect(()=>setTitle(t("grupos.nuevo.titulo")), [])

    useEffect(()=>{
        console.log("checo el params", grupoID)
        if(grupoID && user && user.user_id){
            firebase.database().ref("grupos").child(user.user_id).child(grupoID).once("value", snap=>{
                setForm(snap.val())
            })
        }
    }, [grupoID, user])
    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("grupos.nuevo.bread"), to: "/panel/groups/list"}, t("grupos.nuevo.titulo")]} />
            
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive table-hover fs-14">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">{t("grupos.nuevo.titulo")}</h4>
                            </div>
                            <div class="card-body">
                                <div class="basic-form">
                                    <form onSubmit={guardarGrupo}>
                                        <div class="mb-3">
                                            <label class="form-label">{t("grupos.nuevo.nombre")} <span className="text-danger">(required)</span>:</label>
                                            <input type="text" value={form.nombre} onChange={ev=>setForm(form=>({...form, nombre: ev.target.value}))} required 
                                                class="form-control input-default " name="nombre"  />
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Notes:</label>
                                            <input type="text" value={form.escuela} onChange={ev=>setForm(form=>({...form, escuela: ev.target.value}))} 
                                                class="form-control input-default " name="escuela"  />
                                        </div>
                                        <div className="d-flex flex-row-reverse">
                                            <button type="submit" class="btn btn-primary mt-4">{t("grupos.nuevo.boton")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
}