import Axios from "axios"
import { useHistory, NavLink} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Constants from "../../Utilities/Constants";

export default function GrupoDetalleEvento ({evento, claseID, setLoading, user, originalClassID}) {
    const history = useHistory();
    const { addToast } = useToasts();
    //console.log("llega a eliminar", user)
    const confirmDelete = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete the Event?");
        
        if (isConfirmed) {
          handleDelete();
        }
    };
    const handleDelete = async () => {
        setLoading(true)
        const response = await Axios.post(
            `${Constants.functionsURL()}/deleteClassEvent`,
            { eventID : claseID, firebaseID: evento.firebaseID}
        )
        console.log("la resp es ", response)
        addToast("The event has been successfully deleted.", { appearance: 'success', autoDismiss: true });
        history.push("/panel/schedule")
    };
    function convertirFecha(fecha) {
        // Divide la fecha en año, mes y día
        const partesFecha = fecha.split("-");
        const año = partesFecha[0];
        const mes = partesFecha[1];
        const dia = partesFecha[2];
    
        // Crea un objeto Date usando los componentes de la fecha
        const fechaObjeto = new Date(año, mes - 1, dia);
    
        // Obtiene el nombre del día y del mes en inglés
        const nombreDia = fechaObjeto.toLocaleString("en-US", { weekday: "long" });
        const nombreMes = fechaObjeto.toLocaleString("en-US", { month: "long" });
    
        // Construye la fecha escrita en inglés
        const fechaIngles = `${nombreMes} ${dia}, ${año}`;
    
        return fechaIngles;
    }

    function convertirHora(hora24) {
        // Divide la hora en horas y minutos
        const partesHora = hora24.split(":");
        const horas = parseInt(partesHora[0]);
        const minutos = partesHora[1];
    
        // Determina si es AM o PM
        const periodo = horas >= 12 ? "PM" : "AM";
    
        // Convierte las horas al formato de 12 horas
        const horas12 = horas % 12 === 0 ? 12 : horas % 12;
    
        // Construye la hora en formato de 12 horas con AM/PM
        const horaAmPm = `${horas12}:${minutos} ${periodo}`;
    
        return horaAmPm;
    }
    

    return <div className="card overflow-hidden">
        <div className="card-header">
            <h3>Event {evento.nombre}</h3>
        </div>
        <div className="card-body">
            <div className="row">
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Event name</p>
                    <h4 className="text-black">{evento.nombre}</h4>
                </div>
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Event description</p>
                    <h4 className="text-black">{evento.descripcion}</h4>
                </div>
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Assigned to</p>
                    <h4 className="text-black">{evento.asignadoA === "grupo" ? "Group" : "Student"}</h4>
                </div>
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Location</p>
                    <h4 className="text-black">{evento.lugar ? evento.lugar : "N/A"}</h4>
                </div>
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Start Date</p>
                    <h4 className="text-black">{convertirFecha(evento.fecha)}</h4>
                </div>
                <div className="col-sm-6 mb-4">
                    <p className="mb-2">Start Time</p>
                    <h4 className="text-black">{convertirHora(evento.horaInicio)}</h4>
                </div>
                <div className="col-sm-6">
                    <p className="mb-2">End Time</p>
                    <h4 className="text-black">{convertirHora(evento.horaFinal)}</h4>
                </div>
            </div>
        </div>
        <div className="card-footer border-0 pt-0">
            <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                    <NavLink to={`/panel/classes/chat/${originalClassID}`} className="btn btn-outline-info btn-sm d-block rounded-0 mt-3 mr-4 mt-md-0" >
                        Message Board
                    </NavLink>
                    {user && user.type === "Teacher" && <NavLink to={`/panel/classes/edit/${claseID}`} className="btn btn-outline-primary btn-sm d-block rounded-0 mt-3 mr-4 mt-md-0" >
                        Edit Event
                    </NavLink>}
                    {user && user.type === "Teacher" && <button className="btn btn-outline-danger btn-sm d-block rounded-0 mt-3 mt-md-0" onClick={confirmDelete}>
                        Delete Event
                    </button>}
                </div>
            </div>
        </div>
    </div>
}