import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import { useHistory, useParams, NavLink } from "react-router-dom";
import axios from "axios";
import Constants from "../../../Utilities/Constants";

export default function ExamenesInforme() {
    const { setTitle } = useContext(LayoutContext);
    const [aplicaciones, setAplicaciones] = useState([]);
    const history = useHistory();  
    const { examID } = useParams();

    const obtenReporte = async () => {
        try {
            const response = await axios.get(Constants.restURL()+"?seccion=obtenReporte&examID="+examID);
            console.log("reporte", response.data)
            setAplicaciones(response.data)
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        setTitle("Questionnaire Report");
        obtenReporte()
    }, []);

    return (
        <PanelLayout title="Questionnaire Report">
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Questionnaire Report"]} />
                    <div class="d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <button onClick={()=> history.goBack()} class="ms-auto rounded-0 btn bgl-primary text-primary">
                            Go Back
                        </button>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <table className="table table-stripped table-hover">
                                <thead>
                                    <tr>
                                        <th>Student</th>
                                        <th>Done</th>
                                        <th>Score</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {aplicaciones.map(ap=><tr>
                                        <td>{ap.usuarioEmail}</td>
                                        <td style={{color: (ap.aplicaciones_id ? "blue": "red") }}>{ap.aplicaciones_id ? "YES": "NO"}</td>
                                        <td>{ ap.aplicaciones_id ? parseFloat(ap.aplicaciones_calificacion).toFixed(2) : "N/A"}</td>
                                        <td>
                                            {ap.aplicaciones_id && <NavLink className="btn btn-outline-info pull-right" to={"/panel/exams/score/"+ap.examenID+"/"+ap.aplicaciones_id}>Score Details</NavLink>}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
}
