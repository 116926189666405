import { useParams, useHistory } from "react-router-dom";
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { LayoutContext } from "../../../Context/LayoutContext";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Check, Close } from '@mui/icons-material';
import Constants from "../../../Utilities/Constants";

export default function ExamenesScore() {
    const { applicationID } = useParams();
    const { setTitle } = useContext(LayoutContext);
    const [examenData, setExamenData] = useState(null);
    const history = useHistory();

    useEffect(() => {
        setTitle("Questionnaire Score");
        obtenerDataExamenes();
    }, []);

    const obtenerDataExamenes = async () => {
        try {
            const response = await axios.get(Constants.restURL()+`?seccion=datosAplicacion&idAplicacion=${applicationID}`);
            console.log("data examen", response.data);
            setExamenData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const renderRespuestas = (pregunta) => {
        return pregunta.respuestas.map(respuesta => (
            <div key={respuesta.id}>
                {respuesta.respuesta} {respuesta.valor === '1' ? <Check style={{ color: 'green' }} /> : <Close style={{ color: 'red' }} />}
            </div>
        ));
    };

    const renderAnswer = (pregunta)=> {
        const respuestaAlumno = examenData.respuestasAlumno.find(respAlumno => respAlumno.idPregunta === pregunta.preguntaID);
        
        const respuestaExamen = pregunta.respuestas.find(resp => resp.id === respuestaAlumno.idRespuesta );
        // console.log("resp alum", respuestaAlumno, respuestaExamen)
        const color = respuestaAlumno.correcto === '1' ? 'blue' : 'red';
        return (
            <div style={{ color }}>
                {respuestaExamen.respuesta}
            </div>
        );
    }

    return (
        <PanelLayout title="Questionnaire Score">
            <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Exam Score"]} />
                    <div class="d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <button onClick={()=> history.goBack()} class="ms-auto rounded-0 btn bgl-primary text-primary">
                            Go Back
                        </button>
                    </div>
                    {examenData && (
                        <div className="card">
                            
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-5 mb-4">
                                        <p class="mb-2">Questionnaire Name</p>
                                        <h4 class="text-black">{examenData.nombre_examen}</h4>
                                    </div>
                                    <div class="col-sm-7 mb-4">
                                        <p class="mb-2">Student Email</p>
                                        <h4 class="text-black">{examenData.aplicaciones_alumnos_email}</h4>
                                    </div>
                                    <div class="col-sm-5 mb-4">
                                        <p class="mb-2">Number of hits</p>
                                        <h4 class="text-black">{examenData.aplicaciones_respuestascorrectas}</h4>
                                    </div>
                                    <div class="col-sm-5 mb-4">
                                        <p class="mb-2">Questionnaire Score</p>
                                        <h4 class="text-black">{parseFloat(examenData.aplicaciones_calificacion).toFixed(2)}</h4>
                                    </div>
                                    <div class="col-sm-7">
                                        <p class="mb-2">Passed</p>
                                        <h4 style={{color: (examenData.aplicaciones_aprobado === '1' ?"blue": "red")}}>{examenData.aplicaciones_aprobado === '1' ? 'Approved' : 'Failed'}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {examenData && (
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Respuestas</h4>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-responsive-sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Pregunta</th>
                                                <th>Respuestas posibles</th>
                                                <th>Respuesta del estudiante</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {examenData.preguntas.map((pregunta, index) => (
                                                <tr key={pregunta.preguntaID}>
                                                    <td>{index + 1}</td>
                                                    <td>{pregunta.pregunta.replace(/<[^>]*>/g, "")}</td>
                                                    <td>{renderRespuestas(pregunta)}</td>
                                                    <td>
                                                        {renderAnswer(pregunta) }
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </PanelLayout>
    );
}
