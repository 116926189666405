import React, { useContext } from "react"
import { UserContext } from "../Context/UserContext"
import { AiOutlineHome } from "react-icons/ai";
import { IoCalendarOutline } from "react-icons/io5";
import { GrTest, GrGroup } from "react-icons/gr";
import { FaPerson } from "react-icons/fa6";
import { NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { LayoutContext } from "../Context/LayoutContext"

export default function Sidebar() {
    const location = useLocation()
    const {t} = useTranslation()
    const { user } = useContext(UserContext)
    const { menuRef, setToggledMenu } = useContext(LayoutContext)
    return (
        <div className="deznav" ref={menuRef}>
            <div className="deznav-scroll">
                {user.type && user.type==="Student" ? 
                    <SideBarAlumno location={location} t={t} setToggledMenu={setToggledMenu}/> : 
                    <SideBarMaestro location={location} t={t} setToggledMenu={setToggledMenu}/> 
                }
                <div className="copyright mt-4">
                    <p>
                        <strong>Skoolar App</strong> © {t("menu.derechos")}
                    </p>
                </div>
            </div>
        </div>
    )
}

const SideBarMaestro = ({location, t, setToggledMenu}) => 
    <ul className="metismenu" id="menu">
        <li className={location.pathname === "/panel" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel" className="ai-icon">
                <AiOutlineHome color={location.pathname === "/panel" ? "white": "black"} size={35} />
                <span className="nav-text">{t("menu.inicio")}</span>
            </NavLink>
        </li>
        <li className={location.pathname.includes("/exams") ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/exams/list" className="ai-icon">
                <GrTest color={location.pathname.includes("/exams") ? "white": "black"} size={35} />
                <span className="nav-text">Questionnaires</span>
            </NavLink>
        </li>
        <li className={location.pathname.includes("/schedule") ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/schedule" className="ai-icon">
                <IoCalendarOutline color={location.pathname.includes("/schedule") ? "white": "black"} size={35} />
                <span className="nav-text">{t("menu.horario")}</span>
            </NavLink>
        </li>
        <li className={location.pathname.includes("/groups/") ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/groups/list" className="ai-icon">
                <GrGroup color={location.pathname.includes("/groups/") ? "white": "black"} size={35} />
                <span className="nav-text">{t("menu.grupos")}</span>
            </NavLink>
        </li>
        <li className={location.pathname.includes("/guests/") ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/guests/list" className="ai-icon">
                <FaPerson color={location.pathname.includes("/guests/") ? "white": "black"} size={35} />
                <span className="nav-text">Guests / Attendees</span>
            </NavLink>
        </li>
    </ul>

const SideBarAlumno = ({location, setToggledMenu}) => 
    <ul className="metismenu" id="menu">
        <li className={location.pathname === "/panel" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel" className="ai-icon" >
                <img width="25px" className="logo-abbr mr-4" src="/images/colored/home.png" alt="" />
                <span className="nav-text">Home</span>
            </NavLink>
        </li>
        <li className={location.pathname === "/panel/students/schedule" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/students/schedule" className="ai-icon">
            <img width="25px" className="logo-abbr mr-4" src="/images/colored/Horario.png" alt="" />
                <span className="nav-text">Schedule</span>
            </NavLink>
        </li>
        <li className={location.pathname === "/panel/exams/mine" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/exams/mine" className="ai-icon">
            <img width="25px" className="logo-abbr mr-4" src="/images/colored/Horario.png" alt="" />
                <span className="nav-text">Questionnaires</span>
            </NavLink>
        </li>
        <li className={location.pathname === "/panel/students/groups" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/students/groups" className="ai-icon">
                <img width="25px" className="logo-abbr mr-4" src="/images/colored/Grupo.png" alt="" />
                <span className="nav-text">Groups</span>
            </NavLink>
        </li>
        <li className={location.pathname === "/panel/students/teachers" ? "mm-active active-no-child" : ""} onClick={()=>setToggledMenu(false)}>
            <NavLink to="/panel/students/teachers" className="ai-icon">
                <img width="15px" height={25} className="logo-abbr mr-4 ml-1" src="/images/colored/user.png" alt="" />
                <span className="nav-text">Hosts</span>
            </NavLink>
        </li>
    </ul>