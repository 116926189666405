import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import PanelLayout from "../../panelLayout/Layout";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
//componentes
import GrupoDetalleEvento from "./grupo"
import AlumnosDetalleEvento from "./alumnos"
import MaterialesDetalleEvento from "./materiales"
import EventLinks from "./Links";
import { LayoutContext } from "../../Context/LayoutContext";
import InternalNotes from "../../Components/InternalNotes";
require("firebase/database")

export default function DetallesClase() {
    let { claseID } = useParams();
    let originalClassID = claseID
    claseID = claseID.split("_")[0]
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const { user } = useContext(UserContext)
    const { setTitle } = useContext(LayoutContext)
    
    useEffect(()=>{
        if(!user || !user.user_id) return false
        firebase.database().ref("clases").orderByChild("googleID").equalTo(claseID).once("value", async (snap)=>{
            const value = snap.val()
            const auxArray = Object.values(value)
            const auxArrayKeys = Object.keys(value)
            if(auxArray && Array.isArray(auxArray) && auxArray.length > 0 ){
                let eventData = auxArray[0]
                let firebaseID = auxArrayKeys[0]
                const profesor = await (await firebase.database().ref("users").child(eventData.accountID).once("value")).val()
                let todosLosAlumnos = []
                let attendees = []
                //si es asignado a alumno
                if(eventData.asignadoA === "alumno") todosLosAlumnos.push(eventData.alumnoSeleccionado.id)
                //si es asignado a grupo
                else {
                    const grupo = await (await firebase.database().ref("grupos").child(eventData.accountID).child(eventData.grupoSeleccionado.id).once("value")).val()
                    todosLosAlumnos = grupo.students
                }
                for (const idAlumno of todosLosAlumnos) {
                    const alumno = await (await firebase.database().ref("alumnos").child(eventData.accountID).child(idAlumno).once("value")).val()
                    console.log("paso x alumno", alumno)
                    attendees.push({ email: alumno.correo, name: alumno.nombre, rsvp: true, idAlumno  })
                    //await createEventAndSendEmail(alumno.nombre, alumno.correo, context.params, current, false, profesor.name)    
                }
                eventData.attendees = attendees
                eventData.profesor = profesor
                if(eventData.recurrente){
                    const combinaciones = generateCombinations(eventData)
                    console.log("las combinaciones", combinaciones)
                    eventData.combinaciones = combinaciones
                }
                eventData.firebaseID = firebaseID
                setData(eventData)
                setLoading(false)
                console.log("el snap es ", value, "todos los alumnos, todosLosAlumnos", attendees)
            }
        })

        
    }, [user])

    function labelDay(diaEspanol) {
      const diasTraduccion = {
          "lunes": "Monday",
          "martes": "Tuesday",
          "miércoles": "Wednesday",
          "jueves": "Thursday",
          "viernes": "Friday",
          "sábado": "Saturday",
          "domingo": "Sunday"
      };
  
      // Convierte el día a minúsculas para manejar entradas en mayúsculas o minúsculas
      const diaMinusculas = diaEspanol.toLowerCase();
  
      // Verifica si el día proporcionado está en el objeto de traducciones
      if (diasTraduccion.hasOwnProperty(diaMinusculas)) {
          return diasTraduccion[diaMinusculas];
      } else {
          return "Día no válido";
      }
  }
  
    const generateCombinations = (form) => {
        const combinations = [];
      
        // Recurrence: Active
        //combinations.push('Recurrence: Active');
      
        // Repeat every: (depending on the type of repetition)
        if (form.tipoRepetir === 'dias') {
          if (form.repetirCada) {
            //combinations.push(`Repeat every: ${form.repetirCada} days`);
          }
        } else if (form.tipoRepetir === 'semana') {
          //combinations.push('Repeat every: 1 week');
        } else {
          combinations.push('Lessons every: 1 month');
        }
      
        // Days: (only if days are selected)
        if (form.tipoRepetir === 'semana' && form.dias.length > 0) {
          const auxdays = form.dias.map(dia=>labelDay(dia))
          const selectedDays = auxdays.join(', ');
          combinations.push(`Lessons every: ${selectedDays}`);
        }
      
        // Ends: (depending on the selected option)
        if (form.termina === 'el') {
          combinations.push(`Ends on: ${form.fechaFinal}`);
        } else if (form.termina === 'despues') {
          combinations.push(`Ends after ${form.repeticiones} lessons`);
        } else if (form.termina === 'nunca') {
            combinations.push(`Ends: never`);  
        } else {
          combinations.push(`Ends: ${form.termina}`);
        }
      
        return combinations;
      };
      
      setTitle("Event Details")

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={["Home", "Event Details"]} />
            
            { !loading ? <div className="row">
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-12">
                            <GrupoDetalleEvento originalClassID={originalClassID} user={user} evento={data} claseID={claseID} setLoading={setLoading}/>
                        </div>
                        <div className="col-md-12">
                        { data.links && <EventLinks evento={data} user={user}  /> }
                        </div>
                        <div className="col-md-12">
                        { data.materiales && <MaterialesDetalleEvento evento={data} user={user} changePrice={()=>{}} eventoID={4352342} /> }
                        </div>
                        <div className="col-md-12">
                        { user && user.user_id && <InternalNotes route={`internal-notes/${user.user_id}/classes/${claseID}`} /> }
                        </div>
                    </div>
                </div>
                
                <div className="col-md-5">
                    {data.recurrente && <div class="card text-white bg-primary" style={{height: "auto"}}>
                        <ul class="list-group list-group-flush">
                        {data.combinaciones.map((combinacion, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between">
                            <span className="mb-0">{combinacion}</span>
                        </li>
                        ))}
                        </ul>
                    </div>}
                    {data.notas && <div class="card" style={{height: "auto"}}>
                        <div class="card-header">
                            <h4 class="card-title">Notes</h4>
                        </div>
                        <div class="card-body">
                            {data.notas}
                        </div>
                    </div>}
                    <div>
                        <AlumnosDetalleEvento evento={data} user={user} />
                    </div>
                </div>
            </div> : <div className="row">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div>}
        </div>    
    </div>
}
