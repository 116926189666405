import firebase from "firebase/app"
import { UserProvider } from "./Context/UserContext"
import { LayoutProvider } from "./Context/LayoutContext"
import "./Utilities/Multilenguaje"
import "./App.css"
import MainRouter from "./Router/MainRouter"
import { ToastProvider } from "react-toast-notifications"
import i18n from "./Utilities/Multilenguaje"

i18n.changeLanguage("en")
console.log("la location", window.location.host)

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: "AIzaSyDTZB87qKnvU0VFN7uGBGnJ1F75xbxJQi0",
        authDomain: "grooply-afaaa.firebaseapp.com",
        databaseURL: "https://grooply-afaaa-default-rtdb.firebaseio.com",
        projectId: "grooply-afaaa",
        storageBucket: "grooply-afaaa.appspot.com",
        messagingSenderId: "740523949618",
        appId: "1:740523949618:web:691178532a3aa76730062b",
        measurementId: "G-DPFPWLYV42"
    })
    // if(window.location.host === "localhost:3000"){
    //     var db = firebase.database();
    //     db.useEmulator("localhost", 9000);
    //     const auth = firebase.auth();
    //     auth.useEmulator("http://127.0.0.1:9099");
    // }
} else {
    firebase.app() // if already initialized, use that one
}

function App() {
    return <ToastProvider>
        <UserProvider>
            <LayoutProvider>
                <MainRouter/>
            </LayoutProvider>
        </UserProvider>
    </ToastProvider>
}

export default App
