import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Breadcrumb from "../../panelLayout/Breadcrumb";
import firebase from "firebase"
import { UserContext } from "../../Context/UserContext";
import { useTranslation } from "react-i18next";
import { LayoutContext } from "../../Context/LayoutContext";
import StudentsTableList from "./StudentListComponents/StudentsTableList";
import EmptyStudentsList from "./StudentListComponents/EmptyStudentsList";
require("firebase/database")

export default function Alumnos() {
    const {t} = useTranslation()
    const [data, setData] = useState({})
    const { user } = useContext(UserContext)
    const { setTitle } = useContext(LayoutContext)
    const borrarGrupo = (alumnoID, alumnoNombre) => {
        const response = window.confirm(`${t("students.list.eliminar")} ${alumnoNombre}?`)
        if(response) firebase.database().ref("alumnos").child(user.user_id).child(alumnoID).set(null)
    }
    useEffect(()=>{
        if(user && user.user_id){
            firebase.database().ref("alumnos").child(user.user_id).on("value", snap=>{
                const alumnos = snap.val()
                if(alumnos) setData(alumnos)
                else setData({})                        
            })
        }
    }, [user])

    useEffect(()=>setTitle(t("students.list.title")), [])

    return <div className="content-body">
        <div className="container-fluid">
            <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, t("students.list.title")]} />
            
            <div className="row">
                <div className="col-12">
                    <div class="form-head d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink class="ms-auto rounded-0 btn bgl-primary text-primary" to={`/panel/guests/new`} component="a">
                            {t("students.list.nuevo")}
                        </NavLink>
                    </div>
                    { Object.keys(data).length === 0 ? 
                        EmptyStudentsList() : 
                        StudentsTableList(t, data, borrarGrupo) }
                </div>
            </div>
        </div>    
    </div>
}
