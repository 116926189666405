import Breadcrumb from "../../../panelLayout/Breadcrumb";
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import axios from "axios";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Link, NavLink } from "react-router-dom";
import AddCourseModal from "./addCourse";
import { UserContext } from "../../../Context/UserContext";
import Constants from "../../../Utilities/Constants";

export default function ExamenesRegistro() {
    const { setTitle } = useContext(LayoutContext);
    // const [materias, setMaterias] = useState([]);
    const [examenes, setExamenes] = useState([]);
    const { user } = useContext(UserContext)
    const [selectedMateria, setSelectedMateria] = useState("");
    // const [openedModal, setOpened] = useState(false);

    useEffect(() => {
        setTitle("Questionnaires List");
        obtenerExamenes();
    }, []);

    // const obtenerMaterias = async () => {
    //     try {
    //         const response = await axios.get(Constants.restURL()+"?seccion=obtenerCursosConExamenes&userID="+user.user_id);
    //         console.log(response.data)
    //         setMaterias(response.data);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };

    const obtenerExamenes = async () => {
        try {
            const response = await axios.get(Constants.restURL()+`?seccion=mostrarMisExamenes&userID=${user.user_id}`);
            console.log("lo de examenes", response.data)
            setExamenes(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // const handleMateriaChange = (e) => {
    //     const idMateria = e.target.value;
    //     setSelectedMateria(idMateria);
    //     //obtenerExamenes(idMateria);
    // };

    return (<div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Questionnaires List"]} />
                    {/* <div class="d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <button onClick={()=> setOpened(true)} class="ms-auto rounded-0 btn bgl-primary text-primary">
                            New course
                        </button>
                        <AddCourseModal
                            open={openedModal}
                            setOpen={setOpened}
                            obtenerMaterias={obtenerMaterias}
                            userID={user.user_id}
                        />

                    </div> */}
                    <div class="d-flex mb-3 mb-md-5 align-items-center  flex-row-reverse">
                        <NavLink to={"/panel/exams/new/"} className="ms-auto rounded-0 btn bgl-primary text-primary">Add questionnaire</NavLink>
                    </div>
                    {examenes.length > 0 ? <div className="card">
                        <div className="card-body pt-2 pb-2">
                            <table className="table table-stripped table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Questionnaire name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {examenes.map((examen, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{examen.nombre}</td>
                                            <td>{examen.estado === "1" ? "Active": "Inactive"}</td>
                                            <td>
                                                <div class="btn-group">
                                                    <Link to={`/panel/exams/questions/${examen.id}`}><button type="button" class="btn btn-sm btn-outline-primary">Q&A</button></Link>
                                                    <Link to={`/panel/exams/reports/${examen.id}`}><button type="button" class="btn btn-sm btn-outline-primary">Reports</button></Link>
                                                    <button type="button" class="btn btn-sm btn-outline-primary">Archive</button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div> : <div className="d-flex align-items-center flex-column mt-4">
                        <h3>There are no registered Questionnaires, start by adding one.</h3>
                        <PlaylistAddIcon style={{ fontSize: 80, color: "rgb(182 136 243)" }} color="#ebdffb" />
                    </div>}
                </div>
            </div>
    );
}
