import { useContext, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import firebase from "firebase"
import { useParams } from "react-router-dom";
import { UserContext } from "../../../Context/UserContext";
import { LayoutContext } from "../../../Context/LayoutContext";
require("firebase/database")

export default function GruposChat() {
    const { claseID } = useParams("referringUser")
    const { user } = useContext(UserContext)
    const { setTitle } = useContext(LayoutContext)
    const [message, setMessage] = useState("")
    const [groupData, setGroupData] = useState({})
    const [chats, setChats] = useState({})
    const chatbox = useRef(null);
    const form = useRef(null);

    useEffect(()=>{
        firebase.database().ref("classroom-chats").child(claseID).on("value", snap=>{
            const listMessages = snap.val()
            if(listMessages){
                console.log("se pondra", listMessages)
                setChats(listMessages)
            }
        })
    },[])

    // useEffect(()=>{
    //     console.log("checo el params", groupID)
    //     if(groupID && accountID){
    //         firebase.database().ref("grupos").child(accountID).child(groupID).once("value", snap=>{
    //             const auxGroup = snap.val()
    //             console.log("pongo esto en data", auxGroup)
    //             setGroupData(auxGroup)
    //         })
    //     }
    //     if(groupID && user && user.user_id && !accountID){
    //         firebase.database().ref("grupos").child(user.user_id).child(groupID).once("value", snap=>{
    //             const auxGroup = snap.val()
    //             console.log("pongo esto en data", auxGroup)
    //             setGroupData(auxGroup)
    //         })
    //     }
    // }, [groupID, user])

    useEffect(()=> setTitle(`Live Chat 💬`), [])

    useEffect(() => chatbox.current.scrollIntoView({ behavior: "smooth" }), [chats]);

    const onSubmit = async (ev) => {
        ev.preventDefault()
        await sendMessage()
    }
    const sendMessage = async () => {
        await firebase.database().ref("classroom-chats").child(claseID).push({
            message,
            createdTime: new Date().getTime(),
            user: {
                email:user.email,
                name:user.name,
                type: user.type
            }
        })
        setMessage("")
    }
    const onEnterPress = async (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {
          e.preventDefault();
          await sendMessage()
        }
    }

    return <div className="content-body" style={{display: "grid", gridTemplateRows:"60px auto 20%"}}>
            <div className="container-fluid">
                <Breadcrumb pageTitles={[{label: "Home", to: "/"}, `Message Board of the Class`]} />
            </div>
            {/* <Breadcrumb pageTitles={[{label: t("migajas.inicio"), to: "/"}, {label: t("grupos.nuevo.bread"), to: "/panel/groups/list"}, t("grupos.nuevo.titulo")]} /> */}
            <div className="container-fluid" style={{overflowY: "scroll", minHeight: "65vh"}}>
                {Object.values(chats).map(msg=><div class="card" style={{height: "auto"}}>
                    <div class="card-body pr-4 pl-4 pt-1 pb-2">
                    
                        <div class="right-box-padding">
                            <div>
                                <div class="media pt-3 d-sm-flex d-block justify-content-between">
                                    <div class="clearfix d-flex">
                                        <img class="me-3 rounded" width="70" height="70" alt="image" src="/images/male.jpg"/>
                                        <div class="media-body me-2">
                                            <h5 class="text-primary mb-0 mt-1">{msg.user.name}</h5>
                                            <p class="mb-0">{msg.user.type}</p>
                                            <p class="mb-0">{new Date(msg.createdTime).toLocaleString()}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <p class="mb-2">
                                        {msg.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>)}
                <div ref={chatbox} />
            </div>
            <div class="card">
                <div class="card-body pr-4 pl-4 pt-1 pb-2">
                
                    <form class="right-box-padding" onSubmit={onSubmit} ref={form}>
                        <div >
                            <div class="mb-3 pt-3">
                                <textarea autoFocus name="write-email" onKeyDown={onEnterPress} value={message} onChange={ev=>setMessage(ev.target.value)} id="write-email" cols="30" rows="3" class="form-control" placeholder="Write here your message..."></textarea>
                            </div>
                        </div>
                        <div class="text-end">
                            <button class="btn btn-primary btn-sm">Send</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        
    </div>
}