import { useHistory } from "react-router-dom";

export default function StudentsTableList(t, data, borrarGrupo) {
    const history = useHistory();

    return <div className="table-responsive table-hover fs-14">
        <table className="table display mb-4 dataTablesCard  card-table table-stripped">
            <thead>
                <tr>
                    <th>{t("students.list.table.nombre")}</th>
                    {/* <th>{t("students.list.table.padre")}</th>
                    <th>{t("students.list.table.correo")}</th>
                    <th>{t("students.list.table.telefono")}</th> */}
                </tr>
            </thead>
            <tbody>
                {Object.keys(data).map(char => {
                    const student = data[char];
                    return <tr style={{cursor: "pointer", textTransform: "uppercase"}} onClick={() => history.push(`/panel/guests/detail/${char}`)}>
                        <td>{student.nombre}</td>
                        {/* <td>{grupo.padre}</td>
                        <td>{grupo.correo}</td>
                        <td>{grupo.telefono}</td> */}
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
}