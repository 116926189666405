import React, { useContext, useEffect } from "react"
import { UserContext } from "../Context/UserContext"
import Iframe from 'react-iframe'
import { useTranslation } from "react-i18next"
import { LayoutContext } from "../Context/LayoutContext"

export default function PanelLayout() {
    const {t} = useTranslation()
    const { setTitle } = useContext(LayoutContext)  
    const { user } = useContext(UserContext)
    console.log("le user", user)

    useEffect(()=>setTitle(t("migajas.inicio")), [])
    return <div className="content-body">
        <div className="container-fluid">
            Bienvenidos a Grooply.
        </div>
    </div>
}