import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../panelLayout/Breadcrumb";
import { FormControl, FormControlLabel,  FormLabel, Radio, RadioGroup} from "@mui/material";
import AutoCompleteGroups from "../../../Components/AutoCompleteGroups"
import AutoCompleteStudents from "../../../Components/AutoCompleteStudents"
import PanelLayout from "../../../panelLayout/Layout";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../Context/LayoutContext";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../../../Context/UserContext";
import firebase from "firebase"
import Constants from "../../../Utilities/Constants";
require("firebase/database")

export default function ExamenesRegistro() {
    // const { courseID } = useParams();
    const { user } = useContext(UserContext)
    const { setTitle } = useContext(LayoutContext);
    const [form, setForm] = useState({
        name: "", intro: ""
    })
    const { addToast } = useToasts()
    const history = useHistory();

    useEffect(() => {
        setTitle("Add new Questionnaire");

    }, []);


    async function getAttendees () {
        let todosLosAlumnos = []
        const attendees = []
        //si es asignado a alumno
        if(form.asignadoA === "alumno") todosLosAlumnos.push(form.alumnoSeleccionado.id)
        //si es asignado a grupo
        else {
            const grupo = await (await firebase.database().ref("grupos").child(user.user_id).child(form.grupoSeleccionado.id).once("value")).val()
            todosLosAlumnos = grupo.students
        }
        //ahora por cada alumno hacemos...
        for (const idAlumno of todosLosAlumnos) {
            const alumno = await (await firebase.database().ref("alumnos").child(user.user_id).child(idAlumno).once("value")).val()
            console.log("paso x alumno", alumno)
            attendees.push({ email: alumno.correo, name: alumno.nombre, rsvp: true, idAlumno  })
            //await createEventAndSendEmail(alumno.nombre, alumno.correo, context.params, current, false, profesor.name)    
        }
        return attendees
    }

    async function enviarDatos(evento) {
        evento.preventDefault();
        if(!form.asignadoA){
            addToast("All the fields are required.", { appearance: 'error', autoDismiss: true });
            return false;
        } else if (form.asignadoA && !form.grupoSeleccionado && !form.alumnoSeleccionado) {
            addToast("All the fields are required.", { appearance: 'error', autoDismiss: true });
            return false;
        }
        const attendees = await getAttendees()
        const payload = {...form, attendees, materia: null, user: user.user_id}
        console.log("datos", payload)
        axios.post(Constants.restURL()+"?seccion=registrarExamen", payload)
            .then(response => {
                addToast("The exam has been recorded successfully", { appearance: 'success', autoDismiss: true });
                history.push("/panel/exams/list")
            })
            .catch(error => {
                console.error("Error al registrar el examen:", error);
                // Aquí puedes manejar cualquier error que ocurra durante el registro del examen
            });
    }


    return ( <div className="content-body">
                <div className="container-fluid">
                    <Breadcrumb pageTitles={["Home", "Add new Questionnaire"]} />
                    <div className="card">
                        <div className="card-body">
                            <form id="nuevoExamen" className="text-black" onSubmit={enviarDatos}>
                                <div className="form-group">
                                    <label>Questionnaire Name <span className="text-danger">(required)</span></label>
                                    <input type="text" className="form-control" name="nombre" placeholder="Example: exam second unit" required
                                    value={form.name}
                                    onChange={ev=>setForm(form=>({...form, name: ev.target.value}))}/>
                                </div>
                                <div className="form-group">
                                    <label>Introduction <span className="text-danger">(required)</span></label>
                                    <textarea className="form-control" name="intro" id="cuadroIntroRegistrar" rows="5"
                                    value={form.intro} required
                                    onChange={ev=>setForm(form=>({...form, intro: ev.target.value}))}></textarea>
                                </div>
                                <div className="form-group">
                                    <FormControl required>
                                        <FormLabel id="demo-radio-buttons-group-label">Asignado a <span className="text-danger">(required)</span>:</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            aria-required
                                            value={form.asignadoA}
                                            onChange={ev=>setForm(form=>({...form, asignadoA: ev.target.value}))}
                                        >
                                            <FormControlLabel value="grupo"  control={<Radio />} label={"Group"} />
                                            <FormControlLabel value="alumno"  control={<Radio />} label={"Student"} />
                                        </RadioGroup>
                                    </FormControl>
                                    { form.asignadoA ? form.asignadoA === "grupo" ? <div class="mb-3">
                                        <label class="form-label">Select a Group:</label>
                                        <AutoCompleteGroups user={user} value={form.grupoSeleccionado} label={"Select a group"}
                                                onChange={val=>setForm(form=>({...form, grupoSeleccionado: val}))}/>
                                    </div> : <div class="m b-3">
                                        <label class="form-label">Select a Student:</label>
                                        <AutoCompleteStudents  user={user} value={form.alumnoSeleccionado}  label={"Select a Student"}
                                                onChange={val=>setForm(form=>({...form, alumnoSeleccionado: val}))}/>
                                    </div> : null}
                                </div>
                                <button type="submit" className="btn btn-primary pull-right">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
}
